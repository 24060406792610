import styled from '@emotion/styled';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';


const HeroSegment1 = styled.div`
  min-height: 100vh;
  background: no-repeat url("hero_mobile.png") center top;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(0, 56, 65, 0.4);

  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;

  @media (min-width: 768px) {
    background: no-repeat url("hero.jpg") center;
  }
  
`

const HeroTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 45vh;
  justify-content: center;
  text-align: center;
  align-items: center;
  h1 {
    color: #EDF2F3;
    margin-top: 0;
    margin-bottom: 0;
  }
  h3 {
    color: #003841;
    margin: 0;
  }
`

const DateDiv = styled.div`
  padding: 4px 12px;
  background: rgba(237, 242, 243, 0.8);
  border-radius: 14px;
  width: fit-content;
`

const CustomExpandIcon = styled(ExpandMoreRoundedIcon)`
  position: absolute;
  animation: expand-icon 2s linear infinite;
  bottom: 0px;
  margin-left: -0.5em;
`

function HeroSegment() {
  return (
    <>
      <HeroSegment1>
        <HeroTitle>
          <h1>
            THE KINTSUGI SIMULATION
          </h1>
          <h1>
            —
          </h1>
          <h2>
            IDEX 2023
          </h2>
          <div>
            <DateDiv>
              <h3>
                20 - 24 FEBRUARY 2023
              </h3>
            </DateDiv>
          </div>

        </HeroTitle>

        <a href="#liveFiringExperience">
          <CustomExpandIcon sx={{
            fontSize: 80,
            color: "#EDF2F3",
          }} />
        </a>

      </HeroSegment1>
    </>
  )
}

export default HeroSegment
