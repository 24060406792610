import HeroSegment from './HeroSegment';
import LiveFiringExperience from './LiveFiringExperience';
import LiveFiringSimulation from './LiveFiringSimulation';
import VisitUs from './VisitUs';

function LandingPage() {
  return (
    <>
      <HeroSegment />
      <LiveFiringExperience />
      <LiveFiringSimulation />
      <VisitUs />
    </>
  )
}

export default LandingPage