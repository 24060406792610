import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#EDF2F3"
    }
  },
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#003841",
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#EDF2F3",
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#EDF2F3",
          "&:before": {
            borderBottomColor: "#EDF2F3",
          },
          "&:after": {
            borderBottomColor: "#EDF2F3",
          },
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#EDF2F3",
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#EDF2F3",
        },
      }
    },
  },
});