import styled from '@emotion/styled';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Card } from '@mui/material';

export const SimulationThumbnailStyle = styled(Card)`
  background-color: #D9D9D9;
  /* height: 182px; */
  width: 323px;
  object-fit: fill;
  aspect-ratio: 16 / 9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`

type SimulationThumbnailParams = {
  sid: string;
  thumbnail: string;
  playVideo: () => void;
}
export default function SimulationThumbnail({ sid, thumbnail, playVideo }: SimulationThumbnailParams) {
  return (
    <SimulationThumbnailStyle elevation={3} onClick={playVideo} sx={{
      backgroundImage: `url(${thumbnail.replace('.jpg', '.jpg?width=323&fit=clip')})`,
      "&:hover": {
        backgroundImage: `url(${thumbnail.replace('.jpg', '.gif?time=1s&width=323&fit=clip&duration=4s')})`,
      },
    }} >
      <PlayArrowIcon sx={{
        fontSize: 80,
        color: "#ffffff"
      }} />
    </SimulationThumbnailStyle>
  )
}