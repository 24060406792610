import styled from '@emotion/styled'

export const ButtonPrimaryStyle = styled.button`
  /* font */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  letter-spacing: 0.01em;
  color: #EDF2F3;
  /* button */
  padding: 10px 24px;
  background: rgba(237, 242, 243, 0.01);
  border: 2px solid #EDF2F3;
  backdrop-filter: blur(2.5px);
  border-radius: 36px;

  &:hover {
    color: #003841;
    background: #EDF2F3;
    cursor: pointer;
  }

  &:active {
    color: #003841;
    background: rgba(237, 242, 243, 0.8);
  }
`

export const ButtonSecondaryStyle = styled.a`
  /* font */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  text-align: center;
  letter-spacing: 0.01em;
  color: #EDF2F3;
  /* button */
  padding: 10px 24px;
  background: rgba(237, 242, 243, 0.01);
  border: 2px solid #EDF2F3;
  backdrop-filter: blur(2.5px);
  border-radius: 36px;
  
  text-decoration-line: none;

  &:hover {
    color: #003841;
    background: #EDF2F3;
    cursor: pointer;
  }

  &:active {
    color: #003841;
    background: rgba(237, 242, 243, 0.8);
  }
`

export const ButtonPodStyle = styled.button`
  /* font */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  letter-spacing: 0.01em;
  color: #EDF2F3;
  /* button */
  padding: 10px 24px;
  background: rgba(237, 242, 243, 0.01);
  border: 2px solid #EDF2F3;
  backdrop-filter: blur(2.5px);
  border-radius: 36px;

  &:hover {
    color: #003841;
    border: 2px solid rgba(237, 242, 243, 0.8);
    background: rgba(237, 242, 243, 0.8);
    backdrop-filter: none;
    cursor: pointer;
  }

  &.active {
    color: #003841;
    border: none;
    border: 2px solid #EDF2F3;
    background: #EDF2F3;
    backdrop-filter: none;
  }
`

export const ButtonResetStyle = styled.button`
  /* font */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.01em;
  color: #003841;
  /* button */
  padding: 8px 16px;
  background: #EDF2F3;
  border-radius: 36px;

  &:hover {
    color: #EDF2F3;
    border: none;
    background: none;
    border: 2px solid #EDF2F3;
    cursor: pointer;
  }

  &:active {
    color: #003841;
    border: none;
    background: rgba(237, 242, 243, 0.8);
  }
`

export const ButtonMenuSecondaryStyle = styled.a`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #003841;
  padding: 8px 16px;
  background: #DEE3E2;
  border-radius: 20px;
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: 5px;

  margin-top: 70px;
  
  &:hover {
    background: #15C0E4;
    cursor: pointer;
  }

  &:active {
    background: rgba(237, 242, 243, 0.8);
  }

`
