import styled from '@emotion/styled'
import { ButtonSecondaryStyle } from './CustomButton'


const FooterSegmentWrapper = styled.div`

  div {
    height: 60vh;
    width: 100vw;
    h4 {
      margin-top: 0;
      margin-bottom: 32px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* for the children div */
    div {
      height: 50vh;
      /* to prevent the horizontal scroll from showing */
      width: 49.58vw;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
`

const AboutKintsugi = styled.div`
  background: url("footer-about-kintsugi-bg.png") no-repeat right top;
`

const GetInTouch = styled.div`
  background: url("footer-get-in-touch-bg.png") no-repeat center top;
`


export default function Footer() {
  return (

    <FooterSegmentWrapper>
      <AboutKintsugi>
        <div>
          <h4>
            ABOUT
            <br />
            KINTSUGI
          </h4>
          <ButtonSecondaryStyle href="https://kintsugi.ai/" target="_blank">
            FIND OUT MORE ABOUT US
          </ButtonSecondaryStyle>
        </div>
      </AboutKintsugi>
      <GetInTouch>
        <div>
          <h4>
            GET IN
            <br />
            TOUCH
          </h4>
          <ButtonSecondaryStyle href="https://kintsugi.ai/contact-us/" target="_blank">
            CONNECT WITH US
          </ButtonSecondaryStyle>
        </div>
      </GetInTouch>
    </FooterSegmentWrapper>

  )
}