import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LandingPage from './pages/Landing/LandingPage';
import Root from './pages/Root';
import VideosPage from './pages/VideoPage';


export const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "simulator",
        element: <VideosPage />,
      },
    ],
  }
]);
