import { Stream } from "@cloudflare/stream-react";
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal, Paper, Tooltip } from "@mui/material";
import { useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "./ShareIcons";
import { P1Style, P3Style } from "./Typography";


const PageWrapper = styled.div`
  outline: none;
  background-color: #00272D;
  min-height: 100vh;
  @media (min-width: 768px) {
    min-height: auto;
    margin-top: 20vh;
    margin-left: 10vw;
    margin-right: 10vw;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`
const ModalContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const VideoWrapper = styled(Box)`
  width: 100vw;
  height: 30vh;
  background-color: black;
  margin-bottom: 42px;

  @media (min-width: 768px) {
    width: 895px;
    height: 503px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
  }
`

const DesktopShareVideoWrapper = styled(Box)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    max-width: 20vw;
    flex-direction: column;
    text-align: left;
  }
`

const MobileShareVideoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    display: none;
  }
`
const LinkInput = styled.input`
  margin-top: 3em;
  padding: 4px 16px;
  background: rgba(237, 242, 243, 0.1);
  border: 1px solid #EDF2F3;
  border-radius: 36px;
  width: fit-content;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #EDF2F3;
`

const CopyLinkText = styled(Button)`
  color: #FFFFFF;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 2em;
  background-color: transparent;
  border: none;
`

const Logo = styled('img')`
  margin-top: 1em;
  margin-left: 1em;
  display: unset;

  @media (min-width: 768px) {
    display: none;
    margin-top: 55px;
    margin-left: 80px;
  }
`
// const FacebookLogoStyle = styled(SvgIcon)`
//   width: 25px;
//   height: 25px;
//   :hover{
//     fill: #1572E9;
//   }
// `
// const LinkedinLogo = styled('svg')`
//   &:hover{
//     fill: #0E76A8
//   }
// `
// const TwitterLogo = styled('svg')`
//   &:hover{
//     fill: #00ACEE
//   }
// `

type PageParams = {
  sid: string;
  open: boolean;
  handleClose: () => void;
}

export default function PlayVideoModal({ open, handleClose, sid }: PageParams) {

  const [showCopied, setShowCopied] = useState(false)

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: 'scroll'
        }}
      >
        <PageWrapper>
          <Box sx={{
            height: '80px'
          }}>
            <Link
              to="/" className='navbar-logo'
              onClick={() => {
                window.scrollTo(0, 0)
                handleClose()
              }}
            >
              <Logo src='/logo.svg' />
            </Link>
            <CloseIcon sx={{
              float: 'right',
              pr: '7px',
              pt: '27px',
              "&:hover": {
                cursor: 'pointer'
              },
            }} onClick={handleClose} fontSize="large" />
          </Box>
          <ModalContentWrapper>

            <VideoWrapper>
              <Stream controls src={sid ? sid : ""} />
            </VideoWrapper>

            <DesktopShareVideoWrapper>
              <P1Style>
                Share this video
              </P1Style>
              <P3Style>
                Scan this QR to access video on your mobile device and share the experience with your friends!
              </P3Style>
              <Paper sx={{
                backgroundColor: "white",
                padding: "1em",
                width: "fit-content",
              }}>
                <QRCode value={`https://${window.location.host}/simulator?play=${sid}`} size={128} />
              </Paper>
              <br />

              <P1Style>
                Share
              </P1Style>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px'
              }}>
                <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${window.location.host}/simulator?play=${sid}&amp;src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                  <Tooltip title="Facebook" arrow placement="top">
                    <div>
                      <FacebookIcon />
                    </div>
                  </Tooltip>
                </a>
                <a target="_blank" rel="noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=http://${window.location.host}/simulator?play%3D${sid}`}>
                  <Tooltip title="Linkedin" arrow placement="top">
                    <div>
                      <LinkedinIcon />
                    </div>
                  </Tooltip>
                </a>
                <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A%2F%2F${window.location.host}/simulator?play=${sid}`}>
                  <Tooltip title="Twitter" arrow placement="top">
                    <div>
                      <TwitterIcon />
                    </div>
                  </Tooltip>
                </a>
              </Box>
            </DesktopShareVideoWrapper>
            <MobileShareVideoWrapper>
              <P1Style>
                Share
              </P1Style>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                justifyContent: 'center'
              }}>
                <a target="_blank" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${window.location.host}/simulator?play=${sid}&amp;src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                  <Tooltip title="Facebook" arrow placement="top">
                    <div>
                      <FacebookIcon />
                    </div>
                  </Tooltip>
                </a>
                <a target="_blank" rel="noreferrer" href={`https://www.linkedin.com/shareArticle?mini=true&url=http://${window.location.host}/simulator?play%3D${sid}`}>
                  <Tooltip title="Linkedin" arrow placement="top">
                    <div>
                      <LinkedinIcon />
                    </div>
                  </Tooltip>
                </a>
                <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=https%3A%2F%2F${window.location.host}/simulator?play=${sid}`}>
                  <Tooltip title="Twitter" arrow placement="top">
                    <div>
                      <TwitterIcon />
                    </div>
                  </Tooltip>
                </a>
              </Box>
              <LinkInput value={`${window.location.host}/simulator?play=${sid}`} onChange={() => { }} />
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={showCopied}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Text Copied">
                <CopyLinkText onClick={() => {
                  navigator.clipboard.writeText(`${window.location.host}/simulator?play=${sid}`);
                  setShowCopied(true)
                }}>
                  Copy Link
                </CopyLinkText>
              </Tooltip>
            </MobileShareVideoWrapper>
          </ModalContentWrapper>
        </PageWrapper>
      </Modal>
    </>
  )
}
