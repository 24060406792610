import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { axiosInstance, detectMob } from '../../common/utils';
import { ButtonPrimaryStyle } from '../../components/CustomButton';
import SimulationThumbnail from '../../components/SimulationVideoThumbnail';
import { P1Style } from '../../components/Typography';
import PlayVideoModal from '../../components/PlayVideoModal';

const HeroSegment3 = styled.div`
  /* min-height: 100vh; */
  margin-top: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  padding-left: 5vh;
  padding-right: 5vh;
  p {
    text-align: center;
    max-width: none;
    margin: 32px auto 48px;
  }

  @media (min-width: 768px) {
    /* padding-left: 20vh;
    padding-right: 20vh; */
    padding-left: none;
    padding-right: none;
    p {
      text-align: center;
      max-width: 800px;
    }
  }
`

function LiveFiringSimulation() {

  const [videoModal, setVideoModal] = useState("")
  const [videoList, setVideoList] = useState([])

  useEffect(() => {
    let isSubscribed = true;
    let limitSize = detectMob() ? 3 : 10;

    if (isSubscribed) {

      axiosInstance.get(`/?limit=${limitSize}&status=ready`)
        .then(res => {
          const vlist = res.data.result;
          setVideoList(vlist);
        })
        .catch(err => {
          // console.log(err)
        })
        .finally(() => {
          //remove loading
        })
    }
    return () => {
      isSubscribed = false;
    }
  }, [])

  const playVideo = (e: string) => {
    setVideoModal(e);
  }

  return (
    <>
      <HeroSegment3>
        <h2>
          Live Firing Simulation
        </h2>
        <P1Style>
          Find a video of yourself at the live firing simulation pods and share it with your friends!
        </P1Style>

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {
            videoList.map((e: any) => {
              return (
                <Grid key={e.uid} item xs="auto" md="auto">
                  <SimulationThumbnail thumbnail={e.thumbnail} sid={e.uid} playVideo={() => playVideo(e.uid)} />
                </Grid>
              )
            })
          }
        </Grid>
        <Link
          to="/simulator"
          onClick={() => window.scrollTo(0, 0)}
        >
          <ButtonPrimaryStyle style={{
            marginTop: "64px"
          }}>
            VIEW ALL
          </ButtonPrimaryStyle>
        </Link>
      </HeroSegment3>
      {videoModal && <PlayVideoModal handleClose={() => { setVideoModal("") }} open={videoModal !== ""} sid={videoModal} />}
    </>
  )
}

export default LiveFiringSimulation