import styled from '@emotion/styled';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance, detectMob } from '../common/utils';
import { ButtonPodStyle, ButtonPrimaryStyle, ButtonResetStyle } from '../components/CustomButton';
import PlayVideoModal from '../components/PlayVideoModal';
import SimulationThumbnail from '../components/SimulationVideoThumbnail';
import { P1Style } from '../components/Typography';
import { Helmet } from 'react-helmet'
import { RingLoader } from 'react-spinners';

const HeaderWrapper = styled.div`
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  padding-left: 5vh;
  padding-right: 5vh;
  margin-bottom: 68px;
  margin-top: 120px;
  p {
    text-align: center;
    max-width: none;
    margin: 32px auto 48px;
  }

  @media (min-width: 768px) {
    padding-left: 20vw;
    padding-right: 20vw;
    p {
      text-align: center;
      max-width: 800px;
    }
  }

`

const BodyWrapper = styled.div`
  margin: 0 80px 100px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
  }
`

const VideosWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
`

const timeRange = ['1200', '1300', '1400', '1500', '1600', '1700', '1800', '1900', '2000']

function getEndDate(endDate: string, time: string) {
  // console.log("endDate: ", endDate)
  let endTime = time ? [time.slice(0, 2), "59", "59"].join(':') : "23:59:59"
  return endDate ? `2023-01-${endDate}T${endTime}Z` : "2024-01-08T00:00:00Z"
}

function getStartDate(startDate: string, time: string) {
  // console.log("startDate", startDate)
  let startTime = time ? [time.slice(0, 2), time.slice(2), "00"].join(':') : "23:59:59"
  return startDate ? `2023-01-${startDate}T${startTime}Z` : "2022-01-08T00:00:00Z"
}

function getCreator(pod: number) {
  return pod === 1 ? "pod1" : "pod2"
}

function VideosPage() {
  const [activePod, setActivePod] = useState(1)
  const [videoModal, setVideoModal] = useState("")
  const [videosCount, setVideosCount] = useState(0)
  const [lastVideoTimestamp, setLastVideoTimestamp] = useState("")
  const [date, setDate] = useState("")
  const [time, setTime] = useState<string>("")
  const [videoList, setVideoList] = useState([])
  const [metaThumbnail, setMetaThumbnail] = useState("")
  const [loading, setLoading] = useState(true)
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let isSubscribed = true;
    let limitSize = detectMob() ? 8 : 16;

    if (isSubscribed) {
      setLoading(true)
      setVideoList([])
      setVideosCount(0)

      axiosInstance.get(`/?creator=${getCreator(activePod)}&after=${getStartDate(date, time)}&before=${getEndDate(date, time)}&limit=${limitSize}&include_counts=true&status=ready`)
        .then(res => {
          const vlist = res.data.result.videos;
          const total = res.data.result.range;
          if (vlist.length > 1) {
            const lastVideo = res.data.result.videos.at(-1)
            setLastVideoTimestamp(lastVideo.created)
          }
          setVideoList(vlist);
          setVideosCount(total);
        })
        .catch(err => {
          // console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    return () => {
      isSubscribed = false;
    }
  }, [date, time, activePod])

  useEffect(() => {
    if (searchParams.has('play')) {
      setVideoModal(
        searchParams.get('play') + ""
      )
      axiosInstance.get(`/${searchParams.get('play')}`).then(res => {
        const thumbnail = res.data.result.thumbnail
        setMetaThumbnail(thumbnail)
      })
    }
  }, [searchParams])

  const isActivePod = (pod: number) => pod === activePod;

  const handleDateChange = (event: SelectChangeEvent) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    // console.log(event.target.value);
    setTime(event.target.value);
  };

  const handleReset = () => {
    setDate("");
    setTime("");
  }

  const playVideo = (e: string) => {
    setVideoModal(e);
  }

  const loadMoreVideos = () => {
    let limitSize = detectMob() ? 8 : 16;
    setLoading(true)

    axiosInstance.get(`/?creator=${getCreator(activePod)}&limit=${limitSize}&before=${lastVideoTimestamp}&status=ready`)
      .then(res => {
        // console.log(res.data)
        const vlist = res.data.result;
        if (vlist.length > 1) {
          const lastVideo = res.data.result.at(-1)
          setLastVideoTimestamp(lastVideo.created)
        }
        setVideoList(videoList.concat(vlist));
      })
      .catch(err => {
        // console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {metaThumbnail &&
        <Helmet>
          <meta name="ogimage" property="og:image" content={metaThumbnail} />
          <meta name="twitter:image" content={metaThumbnail} />
        </Helmet>
      }
      <HeaderWrapper>
        <h2>
          Live Firing Simulation
        </h2>
        <img src="simulator.png" alt='live firing simulator' />

        <P1Style>
          Find a video of yourself at the live firing simulation pods and share it with your friends!
        </P1Style>
        <div>
          <ButtonPodStyle
            style={{ marginRight: "32px" }}
            className={isActivePod(1) ? "active" : ""}
            onClick={() => setActivePod(1)}
          >
            POD 1
          </ButtonPodStyle>
          <ButtonPodStyle
            className={isActivePod(2) ? "active" : ""}
            onClick={() => setActivePod(2)}
          >
            POD 2
          </ButtonPodStyle>
        </div>
      </HeaderWrapper>
      <BodyWrapper>
        <FormWrapper>
          <div style={{ marginRight: '20px', marginBottom: '5px' }}>
            <P1Style>
              Filter:
            </P1Style>
          </div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="form-date" >Date</InputLabel>
            <Select
              labelId="form-date-select-standard-label"
              id="form-date-select-standard"
              value={date}
              onChange={handleDateChange}
              label="Date"
              sx={{
                color: "white",
                "&:before": {
                  borderColor: "#EDF2F3"
                },
                "&:after": {
                  borderColor: "#EDF2F3"
                },
                "&:hover": {
                  borderColor: "#EDF2F3"
                },
              }}
            >
              <MenuItem value="">
                None
              </MenuItem>
              <MenuItem value={20}>20 Feb</MenuItem>
              <MenuItem value={21}>21 Feb</MenuItem>
              <MenuItem value={22}>22 Feb</MenuItem>
              <MenuItem value={23}>23 Feb</MenuItem>
              <MenuItem value={24}>24 Feb</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="form-time" >Time Range</InputLabel>
            <Select
              labelId="form-time-select-standard-label"
              id="form-time-select-standard"
              value={time}
              onChange={handleTimeChange}
              label="Time Range"
              sx={{
                color: "white",
                "&:before": {
                  borderColor: "#EDF2F3"
                },
                "&:after": {
                  borderColor: "#EDF2F3"
                },
                "&:hover": {
                  borderColor: "#EDF2F3"
                },
              }}
            >
              <MenuItem value="">
                None
              </MenuItem>
              {
                timeRange.map(e => {
                  return (<MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>)
                })
              }
            </Select>
          </FormControl>
          <ButtonResetStyle onClick={handleReset}>
            RESET
          </ButtonResetStyle>
        </FormWrapper>

        <VideosWrapper>
          <Grid container spacing={2} justifyContent="center" alignItems="center"
            sx={{
              mr: "auto",
              ml: "auto",
            }}
          >
            {
              videoList.map((e: any) => {
                return (
                  <Grid key={e.uid} item xs="auto" md="auto">
                    <SimulationThumbnail thumbnail={e.thumbnail} sid={e.uid} playVideo={() => playVideo(e.uid)} />
                  </Grid>
                )
              })
            }
          </Grid>
          {loading && (<RingLoader color="#ffffff" />)}

          {
            (videosCount > videoList.length) &&
            <ButtonPrimaryStyle style={{
              marginTop: "64px",
              width: "fit-content",
            }} onClick={loadMoreVideos} >
              LOAD MORE
            </ButtonPrimaryStyle>
          }
        </VideosWrapper>
      </BodyWrapper>

      {videoModal && <PlayVideoModal handleClose={() => { setVideoModal("") }} open={videoModal !== ""} sid={videoModal} />}
    </>
  )
}

export default VideosPage