import styled from '@emotion/styled'

const FacebookIconStyle = styled.svg`
  fill: #ffffff;
  &:hover {
    fill: #1572E9;
  }
`
const LinkedinIconStyle = styled.svg`
  fill: #ffffff;
  &:hover {
    fill: #0E76A8;
  }
`
const TwitterIconStyle = styled.svg`
  fill: #ffffff;
  &:hover {
    fill: #00ACEE;
  }
`

export function FacebookIcon() {
  return (
    <FacebookIconStyle width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8167 10.1198H20.8019V7.00015H17.8276C17.8276 7.00015 15.9581 6.95359 14.7658 8.50281C14.7658 8.50281 13.9531 9.26896 13.9404 11.511V13.8503H11V17.1618H13.9404V25.7192H17.3267V17.1618H20.2431L20.6495 13.8503H17.3309V11.511C17.338 11.2485 17.4551 10.0958 18.8167 10.1198Z"/>
    </FacebookIconStyle>
  )
} 

export function LinkedinIcon() {
  return (
    <LinkedinIconStyle width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.29488 12.957H11.0212V24.9417H7.29488V12.957ZM9.15875 7C9.58599 6.99972 10.0037 7.12614 10.3591 7.36327C10.7145 7.6004 10.9916 7.93759 11.1553 8.3322C11.3191 8.72682 11.3622 9.16112 11.2791 9.58021C11.196 9.9993 10.9905 10.3843 10.6886 10.6866C10.3867 10.9889 10.002 11.1949 9.58298 11.2786C9.164 11.3622 8.72963 11.3197 8.33481 11.1564C7.93998 10.9932 7.60242 10.7165 7.36483 10.3615C7.12724 10.0064 7.00028 9.58882 7 9.16158C6.99982 8.8779 7.05549 8.59697 7.16388 8.33481C7.27227 8.07266 7.43123 7.83442 7.63169 7.6337C7.83215 7.43298 8.07019 7.2737 8.33221 7.16497C8.59422 7.05624 8.87507 7.00019 9.15875 7Z" />
    <path d="M13.3613 12.9574H16.9353V14.597H16.9861C17.4827 13.6544 18.6989 12.6611 20.5134 12.6611C24.2849 12.6611 24.9819 15.143 24.9819 18.3698V24.9421H21.2584V19.1148C21.2584 17.725 21.2344 15.936 19.3226 15.936C17.384 15.936 17.0877 17.4513 17.0877 19.0146V24.9406H13.3613V12.9574Z" />
    </LinkedinIconStyle>
  )
} 

export function TwitterIcon() {
  return (
    <TwitterIconStyle width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4406 23.0411C18.9649 23.0411 22.5318 17.6357 22.5318 12.95C22.5318 12.7962 22.5318 12.6438 22.5219 12.4914C23.2159 11.9903 23.8146 11.3691 24.2898 10.6572C23.6431 10.946 22.9568 11.1362 22.2538 11.2216C22.9943 10.7784 23.5489 10.0815 23.8143 9.26034C23.1161 9.67335 22.3527 9.96441 21.5568 10.121C21.0703 9.60217 20.4391 9.24153 19.7451 9.08593C19.0511 8.93033 18.3264 8.98695 17.665 9.24845C17.0035 9.50994 16.436 9.96423 16.036 10.5523C15.6361 11.1404 15.4221 11.8352 15.422 12.5464C15.4219 12.8189 15.4522 13.0906 15.5123 13.3563C14.1021 13.2851 12.7227 12.9184 11.4633 12.2798C10.204 11.6413 9.09294 10.7452 8.20213 9.64976C7.74899 10.4309 7.61042 11.3552 7.8146 12.2349C8.01878 13.1145 8.55039 13.8833 9.30127 14.3849C8.73767 14.3679 8.18646 14.2154 7.69419 13.9405C7.69419 13.956 7.69419 13.9701 7.69419 13.9856C7.69425 14.8044 7.97754 15.5979 8.496 16.2315C9.01446 16.8652 9.73616 17.3 10.5387 17.4622C10.2342 17.5452 9.92016 17.5874 9.60463 17.5878C9.38101 17.5873 9.15796 17.5651 8.93865 17.5215C9.16464 18.2266 9.60557 18.8434 10.1996 19.2854C10.7937 19.7275 11.5112 19.9726 12.2516 19.9864C10.9954 20.9728 9.44373 21.5076 7.84657 21.5046C7.56364 21.5052 7.28094 21.4887 7 21.4552C8.62118 22.4982 10.5087 23.0518 12.4364 23.0496" />
    </TwitterIconStyle>
  )
} 
