import styled from '@emotion/styled'
import { HashLink } from 'react-router-hash-link'


export const P1Style = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.01em;
`

export const P2Style = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  letter-spacing: 0.01em;
`

export const P3Style = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 0.01em;
`

export const P4Style = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 0.01em;
`

export const FormLabelStyle = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.01em;
`

export const MenuLinkStyle = styled(HashLink)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  letter-spacing: 0.01em;
  text-decoration-line: none;
  color: #DEE3E2;
  margin-right: 32px;
  padding-top: 70px;
  border-top: 5px solid transparent;

  &:hover {
    border-top: 5px solid #EDF2F3;
    color: #15C0E4;
    cursor: pointer;
  }

  &.active {
    border-top: 5px solid #15C0E4;
    color: #15C0E4;
  }
`

export const MenuNavLinkStyle = styled(HashLink)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25em;
  letter-spacing: 0.01em;
  text-decoration-line: none;
  color: #DEE3E2;
  margin-right: 32px;
  padding-top: 70px;
  border-top: 5px solid transparent;

  &:hover {
    border-top: 5px solid #EDF2F3;
    color: #15C0E4;
    cursor: pointer;
  }

  &.active {
    border-top: 5px solid #15C0E4;
    color: #15C0E4;
  }
`

export const ButtonLinkStyle = styled.a`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #15C0E4;
`