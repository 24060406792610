import styled from "@emotion/styled"
import { Link } from "react-router-dom"

const StylelessLink = styled(Link)`
  color: unset;
  &:hover{
    color: grey;
  }
`

function ErrorPage() {
  return (
    <div style={{
      height: "100vh"
    }}>
      <h1>
        Sorry, an unexpected error occured.
      </h1>
      <h2>
        404 Not found
      </h2>
      <StylelessLink to="/">
        <h1>
          click here to go homepage
        </h1>
      </StylelessLink>
    </div>
  )
}

export default ErrorPage