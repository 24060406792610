import styled from '@emotion/styled';
import { ButtonLinkStyle, P2Style, P3Style, P4Style } from '../../components/Typography';
const HeroSegment4 = styled.div`
  /* min-height: 100vh; */
  padding-top: 15vh;
  margin-bottom: 160px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const VisitUsSegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  
  p {
    margin: 0;
  }

  hr {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }

  img {
    width: 90%;
    margin-bottom: 3em;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    img {
      padding-right: 100px;
      width: unset;
      margin-bottom: 0;
    }

  hr {
    margin-left: 0;
    margin-right: 0;
    width: unset;
  }
  }
`

function VisitUs() {
  return (
    <>
      <HeroSegment4 id="visit">
        <h2>
          VISIT US
        </h2>

        <VisitUsSegmentWrapper>
          <div>
          {/* <img src='exhibit_map.svg' alt="exhibition map" /> */}
          <img src='exhibit-map.gif' alt="exhibition map" />
            {/* <ExhibitMap src='exhibit-map-dot.gif' width={551} height={414} alt="exhibition map" /> */}
          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <P2Style>
              IDEX 2023
            </P2Style>
            <P3Style>
              Abu Dhabi National Exhibition Centre
              <br />
              20 - 24 February 2023
            </P3Style>

            <hr style={{
              marginTop: '40px',
              marginBottom: '20px',
            }} />

            <P2Style>
              UAE Pavilion
            </P2Style>
            <br />
            <P4Style>
              Stand: 06-B14
            </P4Style>

            <ButtonLinkStyle href="https://goo.gl/maps/LzVfSswXEMCPDT2Z9" target="_blank">
              Getting Here
            </ButtonLinkStyle>
          </div>
        </VisitUsSegmentWrapper>
      </HeroSegment4>
    </>
  )
}

export default VisitUs