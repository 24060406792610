import styled from '@emotion/styled';
import { P1Style } from '../../components/Typography';

const HeroSegment2 = styled.div`
  /* min-height: 100vh; */
  margin-top: 120px;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  p {
    text-align: center;
    max-width: none;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    p {
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      padding-left: 20vw;
      padding-right: 20vw;
    }

    img {
      padding: 0 20vw;
    }
  }
`

function LiveFiringExperience() {
  return (
    <>
      <HeroSegment2 id="liveFiringExperience">
        <h2>
          THE LIVE FIRING EXPERIENCE
        </h2>
        <img src="simulator.png" alt='live firing simulator' />
        <P1Style>
          Visit us at the IDEX 2023 to experience our latest military training technology — a high tech battle ground,
          where you can try your hands at a live firing game at our simulation pods.
        </P1Style>
      </HeroSegment2>
    </>
  )
}

export default LiveFiringExperience