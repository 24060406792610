import { ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';

import './App.css';
import { customTheme } from './CustomTheme';
import { AppRouter } from './routes';

function App() {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <RouterProvider router={AppRouter} />
      </ThemeProvider>
    </>
  );
}

export default App;
