import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Drawer, IconButton, Toolbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ButtonMenuSecondaryStyle } from './CustomButton';
import { MenuLinkStyle, MenuNavLinkStyle } from './Typography';

const TransparentAppBar = styled(AppBar)`
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  
  /* background: transparent; */
  background: #002126BF;
  backdrop-filter: 'blur(5px)';
  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 80px;
  }
`
const Logo = styled('img')`
  margin-top: 1em;
  margin-left: 1em;

  @media (min-width: 768px) {
    margin-top: 55px;
  }
`
const DrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin-right: 0;
  padding-left: 2em;

  a {
    margin-top: 40px;
  }

  a:not(:last-child) {
    width: 100%;
    margin-right: 0;
    padding-top: 0;
    text-align: left;
    border-top: none;
  }
`

const drawerWidth = 300;

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const { hash, pathname } = useLocation();
  const isActive = (iHash: string) => hash === iHash || pathname === iHash;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const trigger = useScrollTrigger();

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{
      textAlign: 'center',
      justifyContent: 'center',
      color: 'white',
    }}>
      <CloseIcon sx={{
        float: 'right',
        pr: '20px',
        pt: '20px'
      }} />
      <DrawerContentWrapper>
        <MenuNavLinkStyle
          to={'/simulator'}
          onClick={() => window.scrollTo(0, 0)}
          className={
            isActive("/simulator") ? "active" : ""
          }
        >
          SIMULATOR PODS
        </MenuNavLinkStyle>
        <MenuLinkStyle to={'/#visit'}
          className={
            isActive("#visit") ? "active" : ""
          }
        >
          VISIT US
        </MenuLinkStyle>
        <ButtonMenuSecondaryStyle href="https://kintsugi.ai/" target="_blank">
          KINTSUGI.AI <EastIcon />
        </ButtonMenuSecondaryStyle>
      </DrawerContentWrapper>
    </Box>
  );

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <TransparentAppBar sx={{
          "align-items": "unset",
        }}>
        <Toolbar sx={{
          "align-items": "unset",
        }}>
          <Link
            to="/" className='navbar-logo'
            onClick={() => window.scrollTo(0, 0)}
          >
            <Logo src='/logo.svg' />
          </Link>
          <Box sx={{
            ml: 'auto',
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'row',
            // alignItems: 'center',
            pb: '10px',
          }}>
            <MenuNavLinkStyle
              to={'/simulator'}
              onClick={() => window.scrollTo(0, 0)}
              className={
                isActive("/simulator") ? "active" : ""
              }
            >
              SIMULATOR PODS
            </MenuNavLinkStyle>
            <MenuLinkStyle
              to={'/#visit'}
              className={
                isActive("#visit") ? "active" : ""
              }
            >
              VISIT US
            </MenuLinkStyle>
            <ButtonMenuSecondaryStyle href="https://kintsugi.ai/" target="_blank">
              KINTSUGI.AI <EastIcon />
            </ButtonMenuSecondaryStyle>
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', display: { sm: 'none' } }}
          >
            <MenuIcon sx={{
              fontSize: 35,
              color: "#ffffff"
            }} />
          </IconButton>

        </Toolbar>
        <Box component="nav">
          <Drawer
            anchor='right'
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box', width: drawerWidth,
                background: 'rgba(0, 56, 65, 0.72)',
                backdropFilter: 'blur(5px)',
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </TransparentAppBar>
    </Slide >
  );
}

export default Navbar;