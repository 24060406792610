import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { Helmet } from 'react-helmet'
function Root() {
  return (
    <>
      <Helmet>
        <meta name="ogimage" property="og:image" content={`${window.location.host}/simulator.png`} />
      </Helmet>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}

export default Root